<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
   
  }
}
</script>

<style>
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; 
}

html{
  
}

body{
  background-color: rgba(10,10,10,1);
//background-color: brown;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
}

.headline {
    
  font-family: "dazzle-unicase", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 60px;
  text-transform: uppercase;
  line-height: 1.2
}

.subtext{
  
  font-size: 30px;
  font-family: "halcom", sans-serif;
  font-weight: 200;
  font-style: italic;

}

.twentytext{
  font-size: 20px;
  font-weight: 400;
  font-family: "halcom", sans-serif;
  font-style: normal;
}

.sixteentext{
  font-size: 18px;
  font-weight: 300;
  font-family: "halcom", sans-serif;
  font-style: normal;
}

body{
  //background-color: white;
  width: 100vw;
  overflow-x: hidden;
   box-sizing: border-box;
   -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none; 
}


body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

body::web


@media screen and (max-width: 1280px), 
screen and (max-height: 950px) {
  .headline {
   
    font-family: "dazzle-unicase", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 50px;
  } 

  .subtext{
   
    font-size: 26px;
    font-family: "halcom", sans-serif;
    font-weight: 200;
    font-style: italic;
  }

  .twentytext{
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
  }
}

@media only screen and (max-width: 1280px) and (max-height: 950px){
  .headline {
   
    font-family: "dazzle-unicase", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 50px;
  } 

  .subtext{
   
    font-size: 26px;
    font-family: "halcom", sans-serif;
    font-weight: 200;
    font-style: italic;

  }

  .twentytext{
    font-size: 18px;
    font-weight: 400;
    
    font-style: normal;
  }
}


@media screen and (max-width: 550px), 
       screen and (max-height: 800px) {
  
        .headline {
          
          font-family: "dazzle-unicase", sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 45px;
        } 
        
        .subtext{
          
          font-size: 26px;
          font-family: "halcom", sans-serif;
          font-weight: 200;
          font-style: italic;
        
        }
        body{
          overflow-x: hidden;
        }
        

        .twentytext{
          font-size: 18px;
          font-weight: 400;
         
          font-style: normal;
        }
        .sixteentext{
          font-size: 16px;
          
        }
}

@media only screen and (max-height: 700px) {
  .headline {
    
    font-family: "dazzle-unicase", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 40px;
  } 
  
  .subtext{
   
    font-size: 22px;
    font-family: "halcom", sans-serif;
    font-weight: 200;
    font-style: italic;
  
  }

  .twentytext{
    font-size: 16px;
    font-weight: 400;
   
    font-style: normal;
  }
}

@media only screen and (max-width: 499px) {
  .headline {
    
    text-align: center;
  } 
  
  .subtext{
   
    text-align: center;
  
  }

  .sixteentext{
    text-align: center;
  }

  .twentytext{
    text-align: center;
  }
}

@media only screen and (max-width: 429px) {
  .headline {
    
    font-family: "dazzle-unicase", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 40px;
  } 
  
  .subtext{
   
    font-size: 24px;
    font-family: "halcom", sans-serif;
    font-weight: 200;
    font-style: italic;
  
  }

  .twentytext{
    font-size: 18px;
    font-weight: 400;
   
    font-style: normal;
  }
}

@media only screen and (max-width: 375px) {
  .headline {
    
    font-family: "dazzle-unicase", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 36px;
  } 
  
  .subtext{
   
    font-size: 20px;
    font-family: "halcom", sans-serif;
    font-weight: 200;
    font-style: italic;
  
  }

  .twentytext{
    font-size: 15px;
    font-weight: 400;
   
    font-style: normal;
  }
}

@media only screen and (max-width: 375px) and (max-height: 690px){
  .sixteentext{
    font-size: 14px;
    
  }
}




</style>
