import { createStore } from 'vuex'
import store from './store.json'
import portfolio from './portfolio.json'
import myProjects from './myProjects.json'
import cvjson from './cv.json'
import skills from './skills.json'
export default createStore({
    state: {
       cordinates: store,
       cv: cvjson,
       portfolio: portfolio,
       skills: skills,
       myProjects: myProjects
    }, 
    mutations: {
        
    },
    actions: {

    }
})