import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {gsap} from 'gsap';
import store from './store'


import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


const app = createApp(App)
app.use(router);
app.use(gsap)
app.mount('#app')
app.use(store);