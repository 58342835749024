import { createRouter, createWebHashHistory } from 'vue-router'



const routes = [
  {
    path: '/:name?/:number?',
    name: 'home',
    meta: { title:'Robin Geramb | Home'},
    component:  () => import('../../public/views/HomeVue.vue'),
    props: true
  },
  {
    path: '/projects/:number?/:page?',
    name: 'projects',
    meta: { title:'Robin Geramb | Projects'},
    component:  () => import('../../public/views/MyProjects.vue'),
    props: true
  },
  {
    path: '/gallery/:number?',
    name: 'gallery',
    displayname: 'Robin Geramb | Gallery',
    component:  () => import('../../public/views/GalleryView.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router